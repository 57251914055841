import { Link } from "gatsby"
import tw, { styled, css } from "twin.macro"
import { Button as StyledButton } from "../../components/Button/Button"
import { StyledCard } from "../../styled/Card"
import { StyledInput } from "../../styled/Forms"
import { StyledPopupButton } from "../../styled/Popup"
import { H2, H4, H5, P, Small } from "../../styled/Text"
import { Button, Field } from "../Form/Form.styled"
import { StyledColumn } from "../Mentor/MentorDetailsHeader.styled"
import { Icon } from "../Icon/Icon"
export const Card = styled(StyledCard)`
  ${tw`bg-transparent md:bg-white flex flex-col w-full mb-10 md:rounded-xl md:mb-8 last:mb-0 md:p-10`}
`

export const PageTitle = tw(H2)`mb-0`
export const Divider = tw.hr`mt-8 mb-8`
export const SubTitle = tw(H4)`mb-0`
export const SubTitleColumn = tw(StyledColumn)`mr-10`
export const InputLabel = tw(H5)`mb-2`
export const BackLink = styled(Link)`
  ${tw`flex items-center my-5`}
  ${css`
    @media (max-width: 768px) {
      ${tw`justify-center`}
    }
  `}
`
export const BackLinkText = tw(P)`text-base ml-4 font-bold`
export const CareerButton = styled(StyledButton)`
  ${tw`block w-full shadow-xl  md:w-auto  md:px-6 md:py-2`}
  ${({ mobile }) => mobile && tw`py-3 mt-6`}
`
export const SaveCareerButton = styled(StyledButton)`
  ${tw`block w-full border-0 shadow-xl bg-pink text-white md:inline-block md:w-auto  md:px-6 md:py-2`}
  ${({ mobile }) => mobile && tw`py-3`}
`

export const DeleteCareerButton = tw(StyledButton)`block w-full border-0  shadow-xl md:inline-block md:w-auto  md:px-6 md:py-2`
export const SaveButton = tw(StyledButton)`block w-full  mt-8 md:inline-block md:w-auto md:px-20`
export const CancelButton = styled(StyledButton)`
  ${tw`block w-full mt-8 md:inline-block md:w-auto md:px-20`}
  ${({ mobile }) => !mobile && tw`ml-4`}
`

export const InputDescription = tw(Small)`mb-4 ml-1`
export const InputField = tw(StyledInput)`mb-1`
export const Title = tw(H2)`text-grey-dark mt-8 w-full md:mt-0 mb-8 text-center`
export const Content = styled(P)`
  ${tw`text-center mb-10 px-24`}
  ${({ mobile }) => mobile && tw`ml-4 px-0`}
`
export const Close = tw.button`top-0 right-0 self-end  mr-4 mt-1`
export const Text = tw(P)``
export const AlertText = tw(P)`text-orange-600 mt-6 text-center flex items-center justify-center ml-4`
export const EditLink = tw.button`text-pink cursor-pointer p-0 border-none font-semibold`
export const Table = tw.table`min-w-full divide-y divide-gray-300`
export const TableHead = tw.thead``
export const TableRow = tw.tr``
export const TableHeader = tw.th`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0`
export const TableBody = tw.tbody`divide-y divide-gray-200 bg-white`
export const TableCell = tw.td`py-5 pl-4 pr-3 text-sm sm:pl-0`
export const EditCell = tw.td`relative py-5 pl-3 pr-4 cursor-pointer text-right text-sm font-medium sm:pr-0`
export const CareerInfoContainer = tw.div`sm:flex sm:items-center mb-8`
export const IconEdit = tw(Icon)``
export const FlexEnd = tw.div`flex justify-end items-center`
export const Flex = tw.div`flex justify-between items-center mt-4`

export const InfoText = tw.p`mt-2 w-3/4 text-sm text-gray-700`
export const ActionButtonContainer = tw.div`mt-4 sm:mt-0 sm:flex-none`
