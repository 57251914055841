import React from "react"
import { withProfileCareerImage } from "./withProfileCareerImage"
import {
  DropZoneContainer,
  DropZoneWrapper,
  ImagePreview,
  UploadedImage,
  FileInputWrapper,
  FileInput,
  Image,
  DropMessage,
  UploadButton,
  FileInputLabel,
  StyledFlexContainer,
  StyledListItem,
  StyledImageContainer,
  FileInputLabelError, SizeRecommendationLabel,
} from "./ProfileCareerImage.styled"
export const ProfileCareerImageUpload = withProfileCareerImage(
  ({ isDragActive, career, index, files, getRootProps, getInputProps, dropErrors }: Props): JSX.Element => {
    return (
      <DropZoneContainer {...getRootProps?.()}>
        <DropZoneWrapper>
          <ImagePreview>
            {files.length === 1
              ? files.map(file => (
                  <StyledListItem key={file.name}>
                    <StyledImageContainer>
                      <UploadedImage src={file.preview} alt="logo" onLoad={() => URL.revokeObjectURL(file.preview)} />
                    </StyledImageContainer>
                  </StyledListItem>
                ))
              : career.image && (
                  <StyledListItem>
                    <StyledImageContainer>
                      <Image image={career.image} alt="logo" />
                    </StyledImageContainer>
                  </StyledListItem>
                )}

            <StyledFlexContainer>
              <FileInput {...getInputProps?.()} />
              {isDragActive ? (
                <DropMessage>Drop the files here</DropMessage>
              ) : (
                <DropMessage>
                  <UploadButton>Upload a file</UploadButton> or drag and drop
                </DropMessage>
              )}
            </StyledFlexContainer>
            <FileInputLabel>PNG, JPG, up to 10MB</FileInputLabel>
            <SizeRecommendationLabel>500px × 500px recommended</SizeRecommendationLabel>
            {dropErrors && (
              <FileInputLabelError>{dropErrors.code === "file-too-large" ? "File is larger than 10mb" : dropErrors.message}</FileInputLabelError>
            )}
          </ImagePreview>
        </DropZoneWrapper>
      </DropZoneContainer>
    )
  }
)

export interface Props {
  files?: any
  isDragActive: boolean
  index: number
  career: any
  getRootProps?: any
  onDrop?: any
  getInputProps?: () => any
  // onChange: (e, i) => any
  dropErrors?: any
}
