import { navigate } from "gatsby-link"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useApp } from "../../hooks/useApp"
import { useFunctions } from "../../hooks/useFunctions"
import { useKlaviyo } from "../../hooks/useKlaviyo"
import { useLocation } from "../../hooks/useLocation"
import { useSanityBlocks, useSanityMentor } from "../../hooks/useSanity"
import { useSearchMentor } from "../../hooks/useSearch"

export const withProfileEdit = Component =>
  React.memo(({ name = "ProfileEdit" }: { name: string }) => {
    const { member } = useApp()
    const { getHTML } = useSanityBlocks()
    const { settings } = useSearchMentor()
    const { mentorUpdate, loading } = useFunctions()
    const { getMentor } = useSanityMentor()
    const { trackEvent } = useKlaviyo()
    const { shopify } = useLocation()

    const [data, setData] = useState({
      title: member?.title,
      role: member?.role,
      company: member?.company,
      location: member?.location,
      country: member?.country ?? "",
      postcode: member?.postcode ?? "",
      rawLocation: member?.rawLocation ?? "",
      description: member?.content?.description?.length ? getHTML(member?.content?.description) : "<p></p>",
      proud: member?.content?.proud?.length ? getHTML(member?.content?.proud) : "<p></p>",
      industryPrimary: member?.preferences?.industryPrimary,
      industrySecondary: member?.preferences?.industrySecondary,
      content: {
        careers: !member?.content?.careers ? [] : member?.content?.careers,
      },

      seeking: member?.preferences?.seeking?.map(item => ({ label: item?.title, value: item?._id, id: item?._id })),
      skills: member?.preferences?.skills?.map(item => ({ label: item?.title, value: item?._id, id: item?._id })),
    })

    const requiredFieldKeys = ["title"]

    const [contact, setContact] = useState(member?.contact)

    const [errors, setErrors] = useState<string[]>([])

    const seekings = [
      ...settings?.skills?.map(({ id, value }) => ({ id: id, value: id, label: value })),
      ...settings?.industriesAll?.map(({ id, value }) => ({ id: id, value: id, label: value })),
    ]

    const skills = [
      ...settings?.skills?.map(({ id, value }) => ({ id: id, value: id, label: value })),
      ...settings?.industriesAll?.map(({ id, value }) => ({ id: id, value: id, label: value })),
    ]

    const industries = [...settings?.industriesAll?.map(({ id, value }) => ({ id: id, value: id, label: value }))]

    const levels = [...settings?.levels?.map(value => ({ value: value, label: value }))]
    /*
  const industries = [
    ...settings?.industriesAll?.map(({ id, value }) => ({ id: id, value: id, label: value })),
  ].filter(({ value }) => !data?.seeking?.filter(item => item?.id === value)?.length)
  */

    const bioPlaceholder =
    "Hi, my name is [x] and I have [x] years experience in [insert industry], working across [insert 2-3 examples].\n\nRight now, I’m working in / taking a break from / starting a new role in… [ix] and would love to gain knowledge in [x].\n\nI live with [friends, family, dogs, cats] in [insert city] and am keen to meet other She Mentors members both virtually and in-person.\n\nI’m passionate about / care about [insert topics] so always happy to chat and share knowledge/experiences in this space!"

    const [bioGenerated, setBioGenerated] = useState(false)

    const handleListChange = (name, value) => {
      setData(prevState => ({
        ...prevState,
        [name]: value,
      }))
    }

    const handlePrimaryLevelChange = value => {
      if (value) {
        setData(prevState => ({
          ...prevState,
          industryPrimary: {
            ...prevState?.industryPrimary,
            level: value,
          },
        }))
      }
    }

    const handlePrimaryIndustryChange = (id, title) => {
      if (id && title) {
        setData(prevState => ({
          ...prevState,
          industryPrimary: {
            industry: {
              _id: id,
              title,
            },
            level: prevState?.industryPrimary?.level,
          },
        }))
      }
    }

    const handleSecondaryLevelChange = value => {
      if (value) {
        setData(prevState => ({
          ...prevState,
          industrySecondary: {
            ...prevState?.industrySecondary,
            level: value,
          },
        }))
      }
    }

    const handleSecondaryIndustryChange = (id, title) => {
      if (id && title) {
        setData(prevState => ({
          ...prevState,
          industrySecondary: {
            industry: {
              _id: id,
              title,
            },
            level: prevState?.industrySecondary?.level,
          },
        }))
      }
    }
    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) => {
        setData(prevState => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }))
      },
      [setData]
    )

    const handleContactChange = useCallback(
      ({ target: { type, name, value, checked } }) => {
        setContact(prevState => ({
          ...prevState,
          [name]: value,
        }))
      },
      [setData]
    )

    const stripHTML = html => {
      if (!html) return ""
      const tmp = document.createElement("DIV")
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ""
    }

    const validate = useCallback(fields => {
      // validation, return the key of any required and empty fields

      const errors = requiredFieldKeys
        .map(key => ({ key, length: stripHTML(fields?.[key])?.length || 0 }))
        ?.filter(x => x.length <= 0)
        ?.map(x => x.key)

      if (!member?.imageUrl) errors.push("image")

      if (errors.length) {
        setErrors(errors)
        return
      }
    }, [])

    useEffect(() => {
      if (data?.description === "<p></p>" && !bioGenerated) {
        setBioGenerated(true)
        handleListChange?.("description", bioPlaceholder.replace(/(\r\n|\r|\n)/g, "<br>"))
      }
    }, [data?.description, bioGenerated])

    const handleSave = useCallback(
      async event => {
        event.preventDefault()
        setErrors([])

        let updateProfile = data

        // Prevent placholder from being saved by accident
        if (stripHTML(updateProfile.description) === bioPlaceholder.replace(/(\r\n|\r|\n)/g, "")) {
          handleListChange("description", "<p></p>")
        }

        // validation, return the key of any required and empty fields
        const errors = requiredFieldKeys
          .map(key => ({ key, length: stripHTML(data?.[key])?.length }))
          ?.filter(x => x.length <= 0)
          ?.map(x => x.key)

        if (!member?.imageUrl) errors.push("image")

        if (errors.length) {
          setErrors(errors)
          return false
        }

        updateProfile.description = updateProfile.description?.includes("<p></p>") || !updateProfile.description ? null : updateProfile.description
        updateProfile.proud = updateProfile.proud?.includes("<p></p>") || !updateProfile.proud ? null : updateProfile.proud
        //@ts-ignore
        updateProfile.contact = contact
        const { status } = await mentorUpdate({ type: "all", data, member })
        if (status === "success") {
          await getMentor({ email: member?.contact?.email })
          trackEvent("Mentor Update: Profile", data)
          navigate("/account/profile")
          return true
        }
        return false
      },
      [data, member, mentorUpdate, getMentor, trackEvent, requiredFieldKeys]
    )

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          data={data}
          seekings={seekings}
          skills={skills}
          industries={industries}
          levels={levels}
          loading={loading}
          image={member?.image}
          contact={contact}
          member={member}
          handleListChange={handleListChange}
          handleChange={handleChange}
          handleSave={handleSave}
          setData={setData}
          handleContactChange={handleContactChange}
          handlePrimaryLevelChange={handlePrimaryLevelChange}
          handleSecondaryLevelChange={handleSecondaryLevelChange}
          handlePrimaryIndustryChange={handlePrimaryIndustryChange}
          handleSecondaryIndustryChange={handleSecondaryIndustryChange}
          apiKey={shopify?.froalaApiKey}
          errors={errors}
          stripHTML={stripHTML}
          validate={validate}
          bioPlaceholder={bioPlaceholder}
        />
      ),
      [data, seekings, member, setData, skills, industries, levels, errors, stripHTML, validate, bioPlaceholder]
    )
  })
