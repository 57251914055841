import React, { useEffect, useMemo, useState, useCallback } from "react"
import { useApp } from "../../hooks/useApp"
import { useFunctions } from "../../hooks/useFunctions"
import { useSanityBlocks, useSanityMentor } from "../../hooks/useSanity"
import { useKlaviyo } from "../../hooks/useKlaviyo"
import { navigate } from "gatsby-link"

import { Props } from "./ProfileCareerEdit"
export const withProfileCareerEdit =
  Component =>
  ({ name = "ProfileCareerEdit", data, setData, index, ...props }: Props) => {
    const { member, setFixed } = useApp()

    const [careerModals, setCareerModals] = useState(new Array(data.content.careers?.length).fill(false))
    const { mentorUpdate, mentorMediaCareerUpload, deleting, adding } = useFunctions()
    const [files, setFiles] = useState([])
    const [pendingDeletion, setPendingDeletion] = useState(null)
    const [formFieldError, setFormFieldError] = useState(false)
    const { getMentor, loading } = useSanityMentor()
    const { trackEvent } = useKlaviyo()
    const [activePopup, setActive] = useState(false)
    const activePopups = careerModals.map((modal, index) => modal)

    if (!activePopup) setFixed(activePopup)

    const setFormErrorWithTimeout = () => {
      setFormFieldError(true)
      setTimeout(() => {
        setFormFieldError(false)
      }, 2000) // 2000 milliseconds (2 seconds)
    }
    useEffect(() => {
      if (pendingDeletion) {
        //@ts-ignore
        saveCareer(pendingDeletion.e, pendingDeletion.key)
      }
    }, [pendingDeletion])

    const handleModal = useCallback(
      index => {
        let updatedCareerModals = new Array(data.content.careers?.length).fill(false)
        if (activePopup) {
          updatedCareerModals[index] = false
          setCareerModals(updatedCareerModals)
          setData(prevState => ({
            ...prevState,
            content: {
              ...prevState.content,
              careers: removeCareerItemFromArray(data.content.careers),
            },
          }))
          setActive(false)
        } else {
          setActive(true)
          updatedCareerModals[index] = true
          setCareerModals(updatedCareerModals)
        }
      },

      [activePopup, data, setData]
    )
    const simplyCloseModal = index => {
      let updatedCareerModals = new Array(data.content.careers?.length).fill(false)
      updatedCareerModals[index] = false
      setCareerModals(updatedCareerModals)
      setActive(false)
    }
    function removeCareerItemFromArray(arr) {
      return arr.filter(item => {
        if (item._key) {
          // Preserve items with a field named _key
          return true
        } else {
          // Filter out items that are empty
          return item.title !== "" && item.company !== "" && item.startDate !== "" && item.endDate !== "" && item.location !== ""
        }
      })
    }

    function createNewCareer() {
      handleModal(data.content.careers.length)
      setData(prevState => ({
        ...prevState,
        content: {
          ...prevState.content,
          careers: [...prevState.content.careers, { title: "", company: "", startDate: "", endDate: "", location: "" }],
        },
      }))
    }

    const handleCareerChange = (e, index) => {
      const { name, value } = e.target

      setData(prevState => {
        // Make a copy of the data to avoid mutating state directly
        const updatedData = { ...prevState }
        // Make a copy of the careers array to avoid mutating it directly
        const updatedCareers = [...prevState.content.careers]
        // Update the specific field of the career object at the given index
        updatedCareers[index][name] = value
        // Update the content object with the updated careers array
        updatedData.content.careers = updatedCareers
        return updatedData
      })
    }
    const deleteCareer = (e, key) => {
      setData(prevState => ({
        ...prevState,
        content: {
          ...prevState.content,
          careers: prevState.content.careers.filter(career => career._key !== key),
        },
      }))

      // @ts-ignore
      setPendingDeletion({ e, key })
    }
    function findIndexOfTrueValue(arr) {
      return arr.findIndex(value => value === true)
    }

    function isCareerValid(career) {
      return (
        career.title.trim() !== "" &&
        career.company.trim() !== "" &&
        career.startDate.trim() !== "" &&
        career.endDate.trim() !== "" &&
        career.location.trim() !== ""
      )
    }
    const saveCareer = useCallback(
      async (event, index) => {
        event.preventDefault()

        const isAllCareersValid = data.content.careers.every(isCareerValid)
        if (!isAllCareersValid) {
          setFormErrorWithTimeout()
          return
        }

        try {
          const newCareers = data?.content?.careers.map(item => {
            const { originalImage, ...rest } = item
            return {
              ...rest,
              image: originalImage,
            }
          })
          data.content.careers = newCareers
          setData(prevState => ({
            ...prevState,
            content: {
              ...prevState.content,
              careers: newCareers,
            },
          }))

          const { status, body } = await mentorUpdate({ type: "content", data, member })

          if (status === "success") {
            let res = await getMentor({ email: member?.contact?.email })

            trackEvent("Mentor Update: Profile", data)
            if (res && files.length) {
              //upload file here

              const newCareers = member?.content?.careers?.map(item => {
                const { originalImage, ...rest } = item
                return {
                  ...rest,
                  image: originalImage,
                }
              })
              member.careers = newCareers

              const { status, body } = await mentorMediaCareerUpload(files[0], res.content.careers[index]._key, member)
              const updatedCareers = await getMentor({ email: member?.contact?.email })

              setData(prevState => ({
                ...prevState,
                content: {
                  ...prevState.content,
                  careers: updatedCareers?.content?.careers,
                },
              }))

              setFiles([])
            }

            simplyCloseModal(index)
          }
        } catch (error) {
          throw Error(error)
        }
      },
      [data, member, getMentor, mentorMediaCareerUpload, trackEvent, pendingDeletion]
    )

    return useMemo(
      () => (
        <Component
          activePopups={activePopups}
          handleCareerChange={handleCareerChange}
          setActive={setActive}
          files={files}
          data={data}
          deleteCareer={deleteCareer}
          saveCareer={saveCareer}
          deleting={deleting}
          adding={adding}
          index={index}
          loading={loading}
          formFieldError={formFieldError}
          careerModals={careerModals}
          handleModal={handleModal}
          createNewCareer={createNewCareer}
          setFiles={setFiles}
        />
      ),
      [activePopup, data, mentorUpdate, handleModal, files, member, formFieldError, deleting, adding]
    )
  }
