import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"

export const DropZoneContainer = styled.div`
  ${tw`col-span-full mb-6 col-span-full`}
`

export const DropZoneWrapper = styled.div`
  ${tw`col-span-full mt-2 flex justify-center rounded-lg border border-dashed border-gray-300 mb-10 px-6 py-10`}
`

export const ImagePreview = styled.div`
  ${tw`text-center`}
`

export const UploadedImage = styled.img`h-14 w-14 mx-auto object-contain`
export const Image = styled(GatsbyImage)`h-14 w-14 mx-auto object-contain`

export const FileInputWrapper = styled.div`
  ${tw`mt-4 flex text-sm leading-6 text-gray-600`}
`

export const FileInput = styled.input`
  ${tw`pl-1`}
`

export const DropMessage = styled.p`
  ${({ isDragActive }) => (isDragActive ? tw`text-pink bg-white font-semibold` : tw`pl-1 text-grey-mid`)}
`

export const UploadButton = styled.span`
  ${tw`text-pink bg-white cursor-pointer font-semibold rounded-md focus-within:outline-none`}
`

export const FileInputLabel = styled.p`
  ${tw`text-xs leading-5 text-grey-mid`}
`

export const SizeRecommendationLabel = styled.p`
  ${tw`text-xxs leading-5 text-grey-mid mt-1`}
`

export const FileInputLabelError = styled.p`
  ${tw`text-xs font-bold leading-5 text-red-500`}
`
export const StyledFlexContainer = styled.div`
  ${tw`mt-4 flex text-sm leading-6 text-gray-600`}
`
export const StyledListItem = styled.li`
  ${tw`flex justify-center`}
`

export const StyledImageContainer = styled.div`
  ${tw`relative mt-1 flex h-10 w-10 flex-none items-center justify-center`}
`
