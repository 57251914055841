import React, { lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ErrorMessage, ErrorSub, Field, FieldSelect, Form, PinkText } from "../Form/Form.styled"
import { ProfileCareerEdit } from "./ProfileCareerEdit"
import { Responsive, StyledColumn, StyledContainer, StyledRow } from "../Mentor/MentorDetailsHeader.styled"
import {
  BackLink,
  BackLinkText,
  CancelButton,
  Card,
  Divider,
  InputDescription,
  InputField,
  InputLabel,
  SaveButton,
  SubTitle,
  SubTitleColumn,
} from "./ProfileEdit.styled"
import { withProfileEdit } from "./withProfileEdit"
import "froala-editor/css/froala_style.min.css"
import "froala-editor/css/froala_editor.pkgd.min.css"
import { Icon } from "../Icon/Icon"
import { MentorDetailsImage } from "../Mentor/MentorDetailsImage"
import { Link } from "gatsby"
import Select from "react-select"
import { countries } from "./countries"

const FroalaEditorComponent = typeof window !== `undefined` ? lazy(() => import("react-froala-wysiwyg")) : () => <></>

export const ProfileEdit = withProfileEdit(
  ({
    data,
    setData,
    seekings = [],
    skills = [],
    industries = [],
    levels = [],
    loading,
    image,
    member,
    contact,
    apiKey,
    handleListChange,
    handleChange,
    handleSave,
    handleContactChange,
    handlePrimaryLevelChange,
    handleSecondaryLevelChange,
    handlePrimaryIndustryChange,
    handleSecondaryIndustryChange,
    errors,
    stripHTML,
    validate,
    bioPlaceholder,
  }: Props): JSX.Element => {
    const [location, setLocation] = useState<any>(null)
    const [locationLoading, setLocationLoading] = useState(false)

    const getLocation = useCallback(async (postalCode: string, country: string) => {
      setLocationLoading(true)
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${postalCode}|country:${country}&key=AIzaSyAiPOUThbSRydfsJpvYlLO48cRq5Xec_UU`
      )
      const res = await response.json()
      setLocationLoading(false)
      const location = res?.results?.[0] || null
      setLocation(location)

      if (location) {
        const placeJson = JSON.stringify(location)
        handleChange?.({
          target: {
            name: "rawLocation",
            value: placeJson,
          },
        })
      } else {
        handleChange?.({
          target: {
            name: "rawLocation",
            value: "",
          },
        })
      }
    }, [])

    const handleCityChange = useCallback(
      (selected: any) => {
        handleChange?.({
          target: {
            name: "city",
            value: selected?.value ?? "",
          },
        })
      },
      [handleChange]
    )

    const handleCountryChange = useCallback(
      (selected: any) => {
        handleChange?.({
          target: {
            name: "country",
            value: selected?.value ?? "AU",
          },
        })
        handleChange?.({
          target: {
            name: "postcode",
            value: "",
          },
        })
        // handleCityChange({ value: selected?.value ? countries[selected?.value][0] : "" })
      },
      [handleChange, handleCityChange]
    )

    const city = useMemo(() => {
      if (location?.postcode_localities?.length === 1) {
        return location?.postcode_localities?.[0]
      }

      return (
        location?.address_components?.find((a: any) => a.types.includes("locality"))?.long_name ||
        location?.address_components?.find((a: any) => a.types.includes("administrative_area_level_2"))?.long_name ||
        location?.address_components?.find((a: any) => a.types.includes("administrative_area_level_1"))?.long_name
      )
    }, [location])

    const refMobile = useRef(null)
    const refDesktop = useRef(null)

    const onHandleSave = useCallback(
      async event => {
        const results = await handleSave(event)
        // results returns a false result on errors
        if (!results) {
          const ref = window.innerWidth < 768 ? refMobile : refDesktop
          const top = window.scrollY + (ref?.current?.querySelector(".error")?.getBoundingClientRect()?.top || 0) - 200
          window.scrollTo({ top, behavior: "smooth" })
        }
      },
      [handleSave]
    )

    useEffect(() => {
      const country = location?.address_components?.find((a: any) => a.types.includes("country"))?.long_name
      handleChange?.({
        target: {
          name: "location",
          value: city && country ? `${city}, ${country}` : "",
        },
      })
    }, [city, location, handleChange])

    useEffect(() => {
      if (!location && data?.rawLocation) {
        setLocation(JSON.parse(data?.rawLocation))
      }
    }, [data?.rawLocation, location])

    useEffect(() => {
      validate(data)
    }, [data])

    return (
      <StyledContainer topSpacing={`md`} spacing={`md`} width={`lg`}>
        <style>{`
          .fr-wrapper.show-placeholder .fr-placeholder { white-space: normal }
          .fr-box.fr-basic.fr-bottom .fr-wrapper { overflow: hidden }
        `}</style>
        <BackLink as={Link} title={`Back to Profile`} theme={`tertiary`} to={"/account/profile"}>
          <Icon name={`chevronLeft`} size={`s`} colour={`tertiary`} />
          <BackLinkText>Back to Profile</BackLinkText>
        </BackLink>
        <br />
        <Card>
          <Responsive screen={`<md`} ref={refMobile}>
            <SubTitle>Your details</SubTitle>
            <br />
            <Field
              type={"text"}
              name={"title"}
              placeholder={"Name *"}
              value={data?.title}
              onChange={handleChange}
              required
              error={errors?.includes("title") && !data?.title?.length}
            />
            {errors?.includes("title") && !data?.title?.length && <ErrorSub className="error">Name is required</ErrorSub>}
            <Field
              type={"text"}
              name={"role"}
              placeholder={"Role / Title *"}
              value={data?.role}
              onChange={handleChange}
              required
              error={errors?.includes("role") && !data?.role?.length}
            />
            {errors?.includes("role") && !data?.role?.length && <ErrorSub className="error">Role is required</ErrorSub>}
            <Field type={"text"} name={"company"} placeholder={"Company"} value={data?.company} onChange={handleChange} />
            <FieldSelect
              placeholder={"Country"}
              options={countries}
              value={data?.country ? { value: data?.country, label: countries.find(c => c.value === data?.country)?.label } : undefined}
              onChange={handleCountryChange}
              error={errors?.includes("country") && !data?.country?.length}
            />
            {errors?.includes("country") && !data?.country?.length && <ErrorSub className="error">Country is required</ErrorSub>}
            <Field
              type={"text"}
              name={"postcode"}
              value={data?.postcode}
              placeholder={"Postcode *"}
              onChange={handleChange}
              onBlur={async (e: any) => {
                if (data?.country) {
                  let { value } = e.target
                  value = value.trim()
                  getLocation(value, data?.country)
                }
              }}
              style={{ marginBottom: data?.postcode ? "0px" : undefined }}
              error={errors?.includes("postcode") && !data?.postcode?.length}
              required
            />
            {errors?.includes("postcode") && !data?.postcode?.length && <ErrorSub className="error">Postcode is required</ErrorSub>}
            {data?.postcode && <InputDescription>{locationLoading ? "Loading.." : city || "No location"}</InputDescription>}

            <Divider></Divider>

            <SubTitle>
              Photo <PinkText>*</PinkText>
            </SubTitle>
            <br />

            <MentorDetailsImage image={image} profile={true} border />
            {errors.includes("image") && !member?.imageUrl?.length && <ErrorMessage className="error">A profile image is required</ErrorMessage>}

            <Divider></Divider>

            <SubTitle>About me</SubTitle>
            <br />
            <Form>
              <Suspense fallback={() => <></>}>
                {typeof window !== "undefined" && (
                  <div className="bio">
                    <FroalaEditorComponent
                      tag="textarea"
                      model={data?.description}
                      onModelChange={value => handleListChange("description", value)}
                      config={{
                        key: apiKey,
                        placeholderText: bioPlaceholder,
                        pastePlain: true,
                        toolbarBottom: true,
                        toolbarButtons: [
                          ["bold", "italic", "underline", "strikeThrough"],
                          ["undo", "redo"],
                        ],
                        heightMin: 400,
                      }}
                    />
                    {errors?.includes("description") && stripHTML(data?.description)?.length < 1 && (
                      <>
                        <style>{`.bio .fr-box.fr-basic { border: 1px solid red }`}</style>
                        <ErrorMessage className="error">Bio is required</ErrorMessage>
                      </>
                    )}
                  </div>
                )}
                <br />

                <InputLabel>I'm most proud of</InputLabel>
                {typeof window !== "undefined" && (
                  <FroalaEditorComponent
                    tag="textarea"
                    model={data?.proud}
                    onModelChange={value => handleListChange("proud", value)}
                    config={{
                      key: apiKey,
                      placeholderText: "Enter your bio...",
                      pastePlain: true,
                      toolbarBottom: true,
                      toolbarButtons: [
                        ["bold", "italic", "underline", "strikeThrough"],
                        ["undo", "redo"],
                      ],
                      heightMin: 120,
                    }}
                  />
                )}

                <br />

                <InputLabel>What I need help with</InputLabel>
                <Select
                  isMulti
                  options={seekings}
                  closeMenuOnSelect={false}
                  defaultValue={data?.seeking}
                  onChange={selectedOption => handleListChange("seeking", selectedOption)}
                />
              </Suspense>
            </Form>
            <Divider></Divider>

            <SubTitle>Industry</SubTitle>
            <br />
            <InputLabel>Primary Industry</InputLabel>
            <InputLabel>Level</InputLabel>
            <Select
              options={levels}
              isClearable="true"
              isSearchable="true"
              defaultValue={{ value: data?.industryPrimary?.level, label: data?.industryPrimary?.level }}
              onChange={selectedOption => handlePrimaryLevelChange(selectedOption?.value)}
            />
            <br />
            <InputLabel>Industry</InputLabel>
            <Select
              options={industries}
              isClearable="true"
              defaultValue={{
                id: data?.industryPrimary?.industry?._id,
                value: data?.industryPrimary?.industry?._id,
                label: data?.industryPrimary?.industry?.title,
              }}
              isSearchable="true"
              onChange={selectedOption => handlePrimaryIndustryChange(selectedOption?.value, selectedOption?.label)}
            />
            <br />
            <InputLabel>Secondary Industry</InputLabel>
            <InputLabel>Level</InputLabel>
            <Select
              options={levels}
              isClearable="true"
              defaultValue={{ value: data?.industrySecondary?.level, label: data?.industrySecondary?.level }}
              isSearchable="true"
              onChange={selectedOption => handleSecondaryLevelChange(selectedOption?.value)}
            />
            <br />
            <InputLabel>Industry</InputLabel>
            <Select
              options={industries}
              isClearable="true"
              defaultValue={{
                id: data?.industrySecondary?.industry?._id,
                value: data?.industrySecondary?.industry?._id,
                label: data?.industrySecondary?.industry?.title,
              }}
              isSearchable="true"
              onChange={selectedOption => handleSecondaryIndustryChange(selectedOption?.value, selectedOption?.label)}
            />

            <Divider></Divider>

            <SubTitle>Skills & Experiences</SubTitle>
            <br />

            <Select
              isMulti
              options={skills}
              closeMenuOnSelect={false}
              defaultValue={data?.skills}
              onChange={selectedOption => handleListChange("skills", selectedOption)}
            />

            <Divider></Divider>
            <SubTitle>Career History</SubTitle>
            {/* Mobile version of career  */}
            <ProfileCareerEdit data={data} setData={setData} />
            <Divider></Divider>
            <br />
            <SubTitle>Contact information</SubTitle>
            <br />
            <InputField
              type={"text"}
              name={"website"}
              placeholder={"Website address (https://yourwebsite.com)"}
              value={contact?.website}
              onChange={handleContactChange}
            />
            <InputDescription>Website address (e.g. https://shementors.com.au)</InputDescription>
            <InputField
              type={"text"}
              name={"instagram"}
              placeholder={"Instagram username"}
              value={contact?.instagram}
              onChange={handleContactChange}
            />
            <InputDescription>Instagram username (e.g. shementors)</InputDescription>
            <InputField type={"text"} name={"facebook"} placeholder={"Facebook username"} value={contact?.facebook} onChange={handleContactChange} />
            <InputDescription>Facebook username (e.g. shementors)</InputDescription>
            <InputField type={"text"} name={"linkedin"} placeholder={"LinkedIn username"} value={contact?.linkedin} onChange={handleContactChange} />
            <InputDescription>LinkedIn username (e.g. shementors)</InputDescription>

            <SaveButton colour={`dark`} onClick={onHandleSave} size={`large`} theme={`primary`} loading={loading}>
              {loading ? "Saving..." : "Save"}
            </SaveButton>
            <CancelButton
              colour={`light`}
              size={`large`}
              theme={`tertiary`}
              onClick={e => {
                e.preventDefault()
                if (typeof window !== "undefined") window.history.back()
              }}
              mobile
            >
              Cancel
            </CancelButton>
          </Responsive>
          <Responsive screen={`md>`} ref={refDesktop}>
            <StyledRow>
              <SubTitleColumn width={"w-1/3"}>
                <SubTitle>Your details</SubTitle>
              </SubTitleColumn>
              <StyledColumn width={"w-full"}>
                <InputLabel>
                  Name <PinkText>*</PinkText>
                </InputLabel>
                <Field
                  type={"text"}
                  name={"title"}
                  placeholder={"Name"}
                  value={data?.title}
                  onChange={handleChange}
                  required
                  error={errors?.includes("title") && !data?.title?.length}
                />
                {errors?.includes("title") && !data?.title?.length && <ErrorSub className="error">Name is required</ErrorSub>}
                <InputLabel>
                  Role / Title <PinkText>*</PinkText>
                </InputLabel>
                <Field
                  type={"text"}
                  name={"role"}
                  placeholder={"Role / Title"}
                  value={data?.role}
                  onChange={handleChange}
                  required
                  error={errors?.includes("role") && !data?.role?.length}
                />
                {errors?.includes("role") && !data?.role?.length && <ErrorSub className="error">Role is required</ErrorSub>}
                <InputLabel>Company</InputLabel>
                <Field type={"text"} name={"company"} placeholder={"Company"} value={data?.company} onChange={handleChange} />
                <InputLabel>
                  Country <PinkText>*</PinkText>
                </InputLabel>
                <FieldSelect
                  options={countries}
                  value={data?.country ? { value: data?.country, label: countries.find(c => c.value === data?.country)?.label } : undefined}
                  onChange={handleCountryChange}
                  error={errors?.includes("country") && !data?.country?.length}
                  correct={!data?.country?.length}
                />
                {errors?.includes("country") && !data?.country?.length && <ErrorSub className="error">Country is required</ErrorSub>}
                <InputLabel>
                  Postcode <PinkText>*</PinkText>
                </InputLabel>
                <Field
                  type={"text"}
                  name={"postcode"}
                  value={data?.postcode}
                  placeholder={"Postcode"}
                  onChange={handleChange}
                  onBlur={async (e: any) => {
                    if (data?.country) {
                      let { value } = e.target
                      value = value.trim()
                      getLocation(value, data?.country)
                    }
                  }}
                  style={{ marginBottom: data?.postcode ? "0px" : undefined }}
                  error={errors?.includes("postcode") && data?.postcode?.length < 4}
                  required
                />
                {errors?.includes("postcode") && !data?.postcode?.length && <ErrorSub className="error">Postcode is required</ErrorSub>}
                {data?.postcode && <InputDescription>{locationLoading ? "Loading.." : city || "No location"}</InputDescription>}

                <Field type={"text"} name={"rawLocation"} value={data?.rawLocation} readOnly style={{ display: "none" }} />
                <Field type={"text"} name={"location"} value={data?.location} readOnly style={{ display: "none" }} />
              </StyledColumn>
            </StyledRow>

            <Divider></Divider>

            <StyledRow>
              <SubTitleColumn width={"w-1/3"}>
                <SubTitle>
                  Photo <PinkText>*</PinkText>
                </SubTitle>
              </SubTitleColumn>
              <StyledColumn width={"w-full"} position={"left"}>
                <div>
                  <MentorDetailsImage image={image} profile={true} size={"small"} border />
                  {errors.includes("image") && member?.incompleteDetails?.includes("profile pic") && (
                    <ErrorMessage className="error">A profile image is required</ErrorMessage>
                  )}
                </div>
              </StyledColumn>
            </StyledRow>

            <Divider></Divider>

            <StyledRow>
              <SubTitleColumn width={"w-1/3"}>
                <SubTitle>About me</SubTitle>
              </SubTitleColumn>
              <StyledColumn width={"w-full"}>
                <Form>
                  <Suspense fallback={() => <></>}>
                    <InputLabel>
                      My bio <PinkText>*</PinkText>
                    </InputLabel>
                    {typeof window !== "undefined" && (
                      <div className="bio">
                        <FroalaEditorComponent
                          tag="textarea"
                          model={data?.description}
                          onModelChange={value => handleListChange("description", value)}
                          config={{
                            placeholderText: bioPlaceholder,
                            pastePlain: true,
                            toolbarBottom: true,
                            toolbarButtons: [
                              ["bold", "italic", "underline", "strikeThrough"],
                              ["undo", "redo"],
                            ],
                            heightMin: 290,
                            key: apiKey,
                          }}
                        />
                        {errors?.includes("description") && stripHTML(data?.description)?.length < 1 && (
                          <>
                            <style>{`.bio .fr-box.fr-basic { border: 1px solid red }`}</style>
                            <ErrorMessage className="error">Bio is required</ErrorMessage>
                          </>
                        )}
                      </div>
                    )}
                    <br />
                    <InputLabel>I'm most proud of</InputLabel>
                    {typeof window !== "undefined" && (
                      <FroalaEditorComponent
                        tag="textarea"
                        model={data?.proud}
                        onModelChange={value => handleListChange("proud", value)}
                        config={{
                          placeholderText: "Enter your bio...",
                          pastePlain: true,
                          toolbarBottom: true,
                          toolbarButtons: [
                            ["bold", "italic", "underline", "strikeThrough"],
                            ["undo", "redo"],
                          ],
                          heightMin: 120,
                          key: apiKey,
                        }}
                      />
                    )}

                    <br />

                    <InputLabel>What I need help with</InputLabel>
                    <Select
                      isMulti
                      options={seekings}
                      closeMenuOnSelect={false}
                      defaultValue={data?.seeking}
                      onChange={selectedOption => handleListChange("seeking", selectedOption)}
                    />
                  </Suspense>
                </Form>
              </StyledColumn>
            </StyledRow>
            <Divider></Divider>
            <StyledRow>
              <SubTitleColumn width={"w-1/3"}>
                <SubTitle>Industry</SubTitle>
              </SubTitleColumn>
              <StyledColumn width={"w-full"}>
                <InputLabel>Primary Industry</InputLabel>
                <InputLabel>Level</InputLabel>
                <Select
                  options={levels}
                  isClearable="true"
                  isSearchable="true"
                  defaultValue={{ value: data?.industryPrimary?.level, label: data?.industryPrimary?.level }}
                  onChange={selectedOption => handlePrimaryLevelChange(selectedOption?.value)}
                />
                <br />
                <InputLabel>Industry</InputLabel>
                <Select
                  options={industries}
                  isClearable="true"
                  defaultValue={{
                    id: data?.industryPrimary?.industry?._id,
                    value: data?.industryPrimary?.industry?._id,
                    label: data?.industryPrimary?.industry?.title,
                  }}
                  isSearchable="true"
                  onChange={selectedOption => handlePrimaryIndustryChange(selectedOption?.value, selectedOption?.label)}
                />
                <br />
                <InputLabel>Secondary Industry</InputLabel>
                <InputLabel>Level</InputLabel>
                <Select
                  options={levels}
                  isClearable="true"
                  defaultValue={{ value: data?.industrySecondary?.level, label: data?.industrySecondary?.level }}
                  isSearchable="true"
                  onChange={selectedOption => handleSecondaryLevelChange(selectedOption?.value)}
                />
                <br />
                <InputLabel>Industry</InputLabel>
                <Select
                  options={industries}
                  isClearable="true"
                  defaultValue={{
                    id: data?.industrySecondary?.industry?._id,
                    value: data?.industrySecondary?.industry?._id,
                    label: data?.industrySecondary?.industry?.title,
                  }}
                  isSearchable="true"
                  onChange={selectedOption => handleSecondaryIndustryChange(selectedOption?.value, selectedOption?.label)}
                />
              </StyledColumn>
            </StyledRow>

            <Divider></Divider>

            <StyledRow>
              <SubTitleColumn width={"w-1/3"}>
                <SubTitle>Skills & Experiences</SubTitle>
              </SubTitleColumn>
              <StyledColumn width={"w-full"}>
                <Select
                  isMulti
                  options={skills}
                  closeMenuOnSelect={false}
                  defaultValue={data?.skills}
                  onChange={selectedOption => handleListChange("skills", selectedOption)}
                />
              </StyledColumn>
            </StyledRow>

            <Divider></Divider>
            <StyledRow>
              <SubTitleColumn width={"w-1/3"}>
                <SubTitle>Career History</SubTitle>
              </SubTitleColumn>

              <StyledColumn width={"w-full"}>
                <ProfileCareerEdit data={data} setData={setData} />
              </StyledColumn>
            </StyledRow>

            <Divider></Divider>
            <StyledRow>
              <SubTitleColumn width={"w-1/3"}>
                <SubTitle>Contact information</SubTitle>
              </SubTitleColumn>
              <StyledColumn width={"w-full"}>
                <InputLabel>Website</InputLabel>
                <InputField
                  type={"text"}
                  name={"website"}
                  placeholder={"Website address (https://yourwebsite.com)"}
                  value={contact?.website}
                  onChange={handleContactChange}
                />
                <InputDescription>Website address (e.g. https://shementors.com.au)</InputDescription>
                <InputLabel>Instagram</InputLabel>
                <InputField
                  type={"text"}
                  name={"instagram"}
                  placeholder={"Instagram username"}
                  value={contact?.instagram}
                  onChange={handleContactChange}
                />
                <InputDescription>Instagram username (e.g. shementors)</InputDescription>
                <InputLabel>Facebook</InputLabel>
                <InputField
                  type={"text"}
                  name={"facebook"}
                  placeholder={"Facebook username"}
                  value={contact?.facebook}
                  onChange={handleContactChange}
                />
                <InputDescription>Facebook username (e.g. shementors)</InputDescription>
                <InputLabel>LinkedIn</InputLabel>
                <InputField
                  type={"text"}
                  name={"linkedin"}
                  placeholder={"LinkedIn username"}
                  value={contact?.linkedin}
                  onChange={handleContactChange}
                />
                <InputDescription>LinkedIn username (e.g. shementors)</InputDescription>
              </StyledColumn>
            </StyledRow>

            <br />

            <StyledRow>
              <SubTitleColumn width={"w-1/3"}></SubTitleColumn>
              <StyledColumn width={"w-full"}>
                <SaveButton colour={`dark`} onClick={onHandleSave} size={`large`} theme={`primary`} loading={loading}>
                  {loading ? "Saving..." : "Save"}
                </SaveButton>
                <CancelButton
                  colour={`light`}
                  size={`large`}
                  theme={`tertiary`}
                  onClick={e => {
                    e.preventDefault()
                    if (typeof window !== "undefined") window.history.back()
                  }}
                >
                  Cancel
                </CancelButton>
              </StyledColumn>
            </StyledRow>
          </Responsive>
          <br />
        </Card>
      </StyledContainer>
    )
  }
)

export interface Props {
  data?: any
  setData: () => void
  seekings?: any
  member: any
  skills?: any
  industries?: any
  levels?: any
  loading?: boolean
  deleting?: boolean
  adding?: boolean
  image?: any
  contact?: any
  apiKey?: any
  errors: string[]
  handleListChange?: (type: string, value: any) => void
  handleChange?: ({
    target: { type, name, value, checked },
  }: {
    target: {
      type?: any
      name: any
      value?: any
      checked?: any
    }
  }) => void
  handleContactChange: (value: string) => void
  handlePrimaryLevelChange: (value: string) => void
  handleSecondaryLevelChange: (value: string) => void
  handlePrimaryIndustryChange: (id: string, title: string) => void
  handleSecondaryIndustryChange: (id: string, title: string) => void
  setFileId: () => void

  files: any
  formFieldError: boolean
  setFiles: () => void
  activePopup: boolean
  handleSave: (event: any) => Promise<boolean>
  handleModal: (index) => void
  handleCareerChange: (e: React.ChangeEvent<HTMLInputElement>, i: number) => void
  deleteCareer: (e, key) => void
  saveCareer: (e, _key) => void
  openModal: (index: number) => void
  closeModal: (index: number) => void
  setActive: () => void
  careerModals: () => void
  setCareerModals: () => void
  createNewCareer: () => void
  stripHTML: (value: string) => string
  validate: (value: any) => boolean
  bioPlaceholder: string
}
