import React, { useMemo, useState, useCallback, useEffect } from "react"
import { useDropzone } from "react-dropzone"

import { useFunctions } from "../../hooks/useFunctions"
import { useSanityMentor } from "../../hooks/useSanity"

export const withProfileCareerImage = Component =>
  React.memo(
    ({
      name = "ProfileCareerImageUpload",
      files,
      career,

      index,
      setFiles,
      ...props
    }: Props) => {
      useEffect(() => {
        //@ts-ignore
        return () => files.forEach(file => URL.revokeObjectURL(file.preview))
      }, [files])
      const onDrop = useCallback(
        async acceptedFiles => {
          setFiles(
            acceptedFiles.map(file =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          )
        },
        [files, setFiles]
      )

      const [dropErrors, setDropErrors] = useState<any>(null)

      const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
        onDrop,
        accept: "image/*",
        maxSize: 10_000_000, // max 10mb
        onDropRejected: rejected => {
          setDropErrors(rejected[0]?.errors?.[0] || null)
        },
        onDropAccepted: () => {
          setDropErrors(null)
        },
      })

      Component.displayName = name
      return useMemo(
        () => (
          <Component
            isDragActive={isDragActive}
            {...props}
            files={files}
            setFiles={setFiles}
            onDrop={onDrop}
            career={career}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            index={index}
            dropErrors={dropErrors}
          />
        ),
        [files, acceptedFiles, onDrop, setFiles]
      )
    }
  )

export interface Props {
  name?: string
  files: File
  index: number
  career: any
  setFiles: (a: any) => void
}
