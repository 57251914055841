import { useMemo } from "react"
import { Icon } from "../Icon/Icon"
import { Popup } from "../Popup/Popup"
import { ProfileCareerImageUpload } from "./ProfileCareerImage"
import { Responsive } from "../Mentor/MentorDetailsHeader.styled"
import {
  Title,
  Content,
  IconEdit,
  DeleteCareerButton,
  FlexEnd,
  Flex,
  SaveCareerButton,
  InputLabel,
  Close,
  Table,
  AlertText,
  CareerInfoContainer,
  InfoText,
  ActionButtonContainer,
  TableBody,
  CareerButton,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  EditCell,
  InputDescription,
  Text,
  InputField,
  EditLink,
} from "./ProfileEdit.styled"
import { StyledColumn } from "../Mentor/MentorDetailsHeader.styled"

import { withProfileCareerEdit } from "./withProfileCareerEdit"
import React from "react"
export const ProfileCareerEdit = withProfileCareerEdit(
  ({
    activePopups,
    setActive,
    handleModal,
    files,
    createNewCareer,
    data,
    setData,

    careerModals,
    setFiles,
    careers,
    formFieldError,
    handleCareerChange,
    deleteCareer,
    saveCareer,
    deleting,
    adding,
  }: Props): JSX.Element => {
    return (
      <>
        <CareerInfoContainer>
          <Responsive screen={`md>`}>
            <Flex>
              <InfoText>A list of your career history including your role, company, timerange and location.</InfoText>
              <ActionButtonContainer>
                <CareerButton colour={`dark`} mobile={false} theme={`primary`} size={`small`} loading={false} onClick={() => createNewCareer()}>
                  Add career
                </CareerButton>
              </ActionButtonContainer>
            </Flex>
          </Responsive>
        </CareerInfoContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>
                <InputLabel>Role</InputLabel>
              </TableHeader>
              <TableHeader>
                <InputLabel>Company</InputLabel>
              </TableHeader>
              <TableHeader>
                <Responsive screen={`md>`}>
                  <InputLabel>Start Date</InputLabel>
                </Responsive>
              </TableHeader>
              <TableHeader>
                <Responsive screen={`md>`}>
                  <InputLabel>End Date</InputLabel>
                </Responsive>
              </TableHeader>
              <TableHeader>
                <Responsive screen={`md>`}>
                  <InputLabel>Location</InputLabel>
                </Responsive>
              </TableHeader>
              <TableHeader>
                <InputLabel>
                  <FlexEnd>
                    <IconEdit name={"edit"} size={"s"} />
                  </FlexEnd>
                </InputLabel>
              </TableHeader>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.content?.careers?.map((career, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Text>{career.title}</Text>
                </TableCell>
                <TableCell>
                  <Text>{career.company}</Text>
                </TableCell>

                <TableCell>
                  <Responsive screen={`md>`}>
                    <Text>{career.startDate}</Text>
                  </Responsive>
                </TableCell>
                <TableCell>
                  <Responsive screen={`md>`}>
                    <Text>{career.endDate}</Text>
                  </Responsive>
                </TableCell>
                <TableCell>
                  <Responsive screen={`md>`}>
                    <Text>{career.location}</Text>
                  </Responsive>
                </TableCell>

                <EditCell>
                  <EditLink color={`pink`} theme={`primary`} onClick={handleModal ? () => handleModal(i) : undefined}>
                    Edit {career.role}
                  </EditLink>
                </EditCell>

                <Popup active={activePopups[i]} setActive={() => handleModal(i)} full>
                  <Close type={`button`} onClick={() => handleModal(i)}>
                    <Icon name={"cross"} size={"s"} colour={"tertiary"} />
                  </Close>
                  <StyledColumn width={"w-full"}>
                    <Title>Add Career History</Title>
                    <Content mobile>Elevate your professional presence by adding your career history!</Content>
                    <InputLabel>Role</InputLabel>
                    <InputField
                      type={"text"}
                      name={"title"}
                      placeholder={"Role"}
                      value={career.title}
                      onChange={e => handleCareerChange(e, i)}
                      required
                    />
                    <InputDescription>Role (e.g. District Manager)</InputDescription>

                    <InputLabel>Company</InputLabel>
                    <InputField
                      type={"text"}
                      name={"company"}
                      placeholder={"Company"}
                      value={career.company}
                      onChange={e => handleCareerChange(e, i)}
                    />
                    <InputDescription>Company (e.g. ABC Pty Ltd.)</InputDescription>

                    <InputLabel>Start Date</InputLabel>
                    <InputField
                      type={"text"}
                      name={"startDate"}
                      placeholder={"Start Date"}
                      value={career.startDate}
                      onChange={e => handleCareerChange(e, i)}
                    />
                    <InputDescription>Start date (e.g. October 2019)</InputDescription>

                    <InputLabel>End Date</InputLabel>
                    <InputField
                      type={"text"}
                      name={"endDate"}
                      placeholder={"End Date"}
                      value={career.endDate}
                      onChange={e => handleCareerChange(e, i)}
                    />
                    <InputDescription>End date (e.g. January 2021 or 'Current')</InputDescription>

                    <InputLabel>Location</InputLabel>
                    <InputField
                      type={"text"}
                      name={"location"}
                      placeholder={"Location"}
                      value={career.location}
                      onChange={e => handleCareerChange(e, i)}
                    />
                    <InputDescription>Location (e.g. Melbourne, Victoria)</InputDescription>

                    <ProfileCareerImageUpload career={career} index={i} files={files} setFiles={setFiles} />

                    <FlexEnd>
                      <SaveCareerButton
                        mobile
                        theme={"dark"}
                        loading={adding}
                        children={adding ? "Saving" : "Save"}
                        onClick={e => saveCareer(e, i)}
                      />
                    </FlexEnd>
                    {formFieldError && (
                      <AlertText>
                        {" "}
                        <Icon name="alert" size="ml" />
                        Please complete all fields
                      </AlertText>
                    )}
                  </StyledColumn>
                </Popup>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Responsive screen={`<md`}>
          <CareerButton colour={`dark`} mobile theme={`primary`} size={`small`} loading={false} onClick={createNewCareer}>
            Add career
          </CareerButton>
        </Responsive>
      </>
    )
  }
)

export interface Props {
  name?: string
  activePopups?: any
  setActive?: (bool: boolean) => void
  loading?: boolean
  files: File
  createNewCareer?: any
  setFiles?: any
  careers: any
  index: any
  formFieldError?: any
  data?: any
  setData?: any
  handleCareerChange?: any
  deleteCareer?: any
  careerModals?: any
  saveCareer?: any
  deleting?: boolean
  adding?: boolean
  handleModal: (bool: boolean) => void
}
